import './hevensSelect';
import './hevensSelect.scss';

export function customSelect(el) {
  $(el).hevensSelect({ disable_search: true });
  return {
    destroy() {
      $(el).hevensSelect('destroy');
    },
  };
}
