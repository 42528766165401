// node-modules
import { TimelineMax, TweenMax } from 'gsap/TweenMax';
import clickOutside from 'click-outside';

// constants
import { SELECTORS } from '../../scripts/constants';

// others
import { createEvent } from '../../scripts/utils/create-event';

// assets
import './Dropdown.scss';


const defaults = {
  element: null,
  triggerElement: '[data-role*=dropdown-title]',
  innerList: '[data-role*="dropdown-inner-list"]',
  innerListItem: '[data-role*="dropdown-inner-item"]',
  activeClass: SELECTORS.active,
  duration: 0.35,
};


export class Dropdown {
  constructor(props) {
    const options = Object.assign({}, defaults, props);
    this.element = options.element;
    this.triggerElements = this.element.querySelectorAll(options.triggerElement);
    this.innerList = this.element.querySelector(options.innerList);
    this.innerListItems = Array.from(this.element.querySelectorAll(options.innerListItem));
    this.duration = options.duration;
    this.durationStagger = options.duration * 0.8;
    this.activeClass = options.activeClass;
    this.activeStatus = false;
    this.animated = false;

    this.unbindClickOutside = null;
    this.toggleDropdown = this.toggleDropdown.bind(this);

    this.toggleEndEvent = 'hevens-dropdown:toggle-end';
    this.init();
  }

  toggleDropdown() {
    if (this.animated) return;
    this.animated = true;
    const tl = new TimelineMax({
      onComplete: () => {
        this.activeStatus = !this.activeStatus;
        this.animated = false;
        this.element.dispatchEvent(createEvent(this.toggleEndEvent));
        window.dispatchEvent(createEvent('resize'));
      },
    });
    if (this.activeStatus) {
      this.element.classList.remove(this.activeClass);
      tl
        .to(this.innerList, this.duration, { height: 0 })
        .staggerTo(this.innerListItems, this.durationStagger, { opacity: 0, y: 20 }, '.05', 0);
    } else {
      this.element.classList.add(this.activeClass);
      TweenMax.set(this.innerList, { height: 'auto' });
      TweenMax.set(this.innerListItems, { opacity: 1, y: 30 });
      tl
        .from(this.innerList, this.duration, { height: 0 })
        .staggerTo(this.innerListItems, this.durationStagger, { opacity: 1, y: 0, clearProps: 'all' }, '.05', 0);
    }
  }

  destroy() {
    this.unbindClickOutside();
  }

  init() {
    this.triggerElements.forEach(trigger => trigger.addEventListener('click', this.toggleDropdown.bind(this)));
    this.unbindClickOutside = clickOutside(this.element, () => {
      if (this.activeStatus) {
        this.element.removeEventListener(this.toggleEndEvent, this.toggleDropdown, { once: true });
        this.toggleDropdown();
      } else if (this.animated) {
        this.element.addEventListener(this.toggleEndEvent, this.toggleDropdown, { once: true });
      }
    });
    TweenMax.set(this.innerList, { height: 0 });
  }
}
