export function createEvent(name) {
  if (typeof (Event) === 'function') {
    return new Event(name);
  }
  const event = document.createEvent('Event');
  event.initEvent(name, true, true);
  return event;
}

export function createCustomEvent(name, props) {
  const defaults = { bubbles: true, cancelable: true, detail: undefined };
  const params = Object.assign({}, defaults, props);
  if (typeof (CustomEvent) === 'function') {
    return new CustomEvent(name, params);
  }

  const event = document.createEvent('CustomEvent');
  event.initCustomEvent(name, params.bubbles, params.cancelable, params.detail);
  return event;
}
